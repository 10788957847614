import React from 'react';
import logo from '../../images/logo-final.png';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Objective = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage(title: { eq: "objective" }) {
        paragraph {
          raw
        }
      }
    }
  `);

  const { contentfulHomePage } = data;
  const paragraph = JSON.parse(contentfulHomePage.paragraph.raw);

  return (
    <section className="hero is-three-quarters-height">
      <div className="hero-body objective-image">
        <div className="columns mb-0">
          <div className="column is-5" />
          <div className="column has-text-centered-mobile">
            <img className="logo-image" src={logo} alt={logo.toString()} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-5" />
          <div className="column">
            <p className="is-size-5 content">{renderRichText(paragraph)}</p>
            <div className="mt-4">
              <Link to="/about/">
                <strong className="is-size-5 " style={{ fontWeight: '600' }}>
                  Learn more
                </strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Objective;
