import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/homepage/Hero';
import Objective from '../components/homepage/Objective';
import Logos from '../components/homepage/Logos';
import Announcements from '../components/news/announcements';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../utils/renderRichText';
import dialo from '../images/logo-dalio.jpg';
import dph from '../images/ma-dph.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-a11y-footnotes/dist/styles.css';
import './page-styles.scss';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      substance: contentfulHomePage(title: { eq: "substance use 101" }) {
        paragraph {
          raw
        }
      }
      facil: contentfulHomePage(title: { eq: "facilitators" }) {
        paragraph {
          raw
        }
      }
      news: contentfulHomePage(title: { eq: "news" }) {
        paragraph {
          raw
        }
      }
    }
  `);

  const { substance, facil } = data;
  const paragraph = JSON.parse(substance.paragraph.raw);
  const facilParagraph = JSON.parse(facil.paragraph.raw);

  return (
    <Layout>
      <Hero />
      <Logos />
      <Objective />
      <section className="section mid-section-one-oh-one has-text-white">
        <div className="hero-body">
          <h3 className="is-size-3 title is-size-3-mobile has-text-white">
            SUBSTANCE USE 101
          </h3>
          <div className="content mt-2">
            <p className="is-size-5">{renderRichText(paragraph)}</p>
          </div>
          <Link to="/one-oh-one" className="is-size-5 has-text-white">
            <strong style={{ fontWeight: '600' }}>Learn more</strong>
          </Link>
        </div>
      </section>
      <section className="section mid-section-bg-image">
        <div className="hero-body">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <h3 className="is-size-3 title is-size-3-mobile has-text-right-desktop pr-4">
                iDECIDE FACILITATORS
              </h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <div className="content">
                <p className="is-size-5">{renderRichText(facilParagraph)}</p>
                <Link to="/facilitators" className="is-size-5">
                  <strong style={{ fontWeight: '600' }}>Learn more</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="hero-body">
          <div className="columns">
            <div className="column">
              <h3 className="is-size-3 title is-size-3-mobile has-text-left-desktop pr-4">
                SUPPORT
              </h3>
            </div>
            <div className="column is-4"></div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-9">
              <p className="is-size-5">
                iDECIDE is funded by a Cooperative Agreement to MGH from the
                Massachusetts Department of Public Health, Office of Youth &
                Young Adult Services’ federal award by the Substance Abuse and
                Mental Health Services Administration.
              </p>
            </div>
            <div className="column">
              <div
                className="content"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={dph} alt={'dph'} style={{ maxWidth: '40%' }} />
              </div>
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-9">
              <p className="is-size-5">
                iDECIDE is also proud to be supported in part by Dalio
                Philanthropies. “Their support will help further the mission of
                iDECIDE promoting education and empowerment over punishment.
                Together, we can help to improve health equity among students
                and ensure responses to substance use infractions at school are
                effective, age-appropriate, and evidence-based for all.
              </p>
            </div>
            <div className="column">
              <div
                className="content"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={dialo} alt={'df'} style={{ maxWidth: '100%' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Announcements title="Job Openings" />
      <section className="section gray-bg anchor" id="bulletin">
        <div className="columns">
          <div className="column">
            <a
              href="https://redcap.link/bulletin.training"
              className="is-link is-size-3"
            >
              <h3>Sign Up Here to Learn More About iDECIDE</h3>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
