import React from 'react';
import choices from '../../images/slogan.png';

const Hero = () => {
  return (
    <section className="hero is-fullheight hero-image">
      <div className="container">
        <div className="hero-body hero-container">
          <figure className="image slogan-image">
            <img src={choices} alt="choices" className="choices-image" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Hero;
