import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HMSlogo from '../../images/logo-hms.png';
import IHRlogo from '../../images/logo-ihr.png';
import DPHlogo from '../../images/logo-dph.png';
import MGHlogo from '../../images/logo-mgh.png';
import './homepage.scss';

const Logos = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage(title: { eq: "Video" }) {
        videoLink
      }
    }
  `);

  return (
    <section className="section has-background-white">
      <div className="columns is-centered is-vcentered is-mobile has-text-centered">
        <div className="column is-1 is-hidden-mobile"></div>
        <div className="column">
          <img className="mgh-logo-width" src={MGHlogo} alt={'MGH logo'} />
        </div>
        <div className="column">
          <img className="hms-logo-width" src={HMSlogo} alt={'HMS logo'} />
        </div>
        <div className="column">
          <img className="dph-logo-width" src={DPHlogo} alt={'DPH logo'} />
        </div>
        <div className="column">
          <img className="ihr-logo-width" src={IHRlogo} alt={'IHR logo'} />
        </div>
        <div className="column is-1 is-hidden-mobile"></div>
      </div>
      <div className="container">
        <p className="is-size-6 is-size-5-mobile has-text-centered">
          iDECIDE was developed by the Center for Addiction Medicine at
          Massachusetts General Hospital (MGH) in collaboration with the Office
          of Youth and Young Adult Services at the Massachusetts Department of
          Public Health (DPH) and the Institute for Health Recovery (IHR).
        </p>
      </div>
      <div className="has-text-centered pt-5 iframe-container">
        <iframe
          src={data.contentfulHomePage.videoLink}
          width="90%"
          height="480"
          frameBorder="0"
          allowFullScreen
          title="video link"
        />
      </div>
    </section>
  );
};

export default Logos;
